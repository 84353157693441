import '$styles/index.css';
import '$styles/index.scss';
// import '$javascript/shared/megamenu/_1_diagonal-movement';
import '$javascript/shared/megamenu/_1_tabs';
import '$javascript/shared/megamenu/_2_dropdown';
import '$javascript/shared/megamenu/_3_mega-site-navigation';
// import '$javascript/shared/video_hero/_1_modal-window';
// import '$javascript/shared/video_hero/_2_modal-video';
import '$javascript/shared/cards/_2_anim-cards';

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css,scss}";

console.info("Bridgetown is loaded!");
